import CustomerAPIService from "./CustomerAPIService";


class LogService extends CustomerAPIService
{
   // addLog = async ( logs:any, data:any = []) => {
   //    const result = await this.post({logs}, '/logs');
        
   //    return result ? result.data : null;
 
   // }

}

export default LogService