import React, { Component } from "react";
import { Layout } from 'antd';
import HeaderView from "./HeaderView";
import FooterView from "./FooterView";
import  { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import APPConfig from "../../config";


const { Content } = Layout;

const LayoutView = (props:any) => {
    const dispatch = useDispatch()
    const [location, setLocation] = useState("");
    const changeLocation = (location_result:any) => {
        setLocation(location_result);
        dispatch({ type: "location", location: location_result });
    };

    const stripePromise = loadStripe(APPConfig.STRIPE_PK)
        return (
            <Layout>
                <HeaderView location={location} onChangeLocation={changeLocation} />
                <Layout>
                    <Content  style={{
                            paddingTop: 12,
                            minHeight: "80vh",
                            backgroundColor: 'white',
                            overflow: 'visible'
                        }}>
                        <Elements stripe={stripePromise}>
                            {props.children}
                        </Elements>
                    </Content>
                </Layout> 
                <FooterView/> 
            </Layout>   
        );
    
}

const mapStateToProps = (state) => ({
    user_location: state.location,
});

export default connect(mapStateToProps)(LayoutView);