import APIService from "./APIService";

class CustomerAPIService extends APIService
{

    constructor (){
        super()
        this.baseURL +="/customers"
    }
    
}

export default CustomerAPIService