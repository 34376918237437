import CustomerAPIService from "./CustomerAPIService";


class LocationService extends CustomerAPIService
{

   getLocation = async (lat:any, long: any) => {
      const result = await this.get('locations/get_location?lat=' + lat +'&long=' + long);
      return result ? result.data : null;
   }

   getAllLocations = async () => {
      const result = await this.get('locations');
      return result ? result.data : null;
   }

   checkLocation = async (lat:any, long: any, location:any = "") => {
      const result = await this.get('locations/check?lat=' + lat +'&long=' + long+'&location=' + location);
      return result ? result.data : null;
   }

   getLocationById = async (id : any ="") => {
      const result = await this.get('locations/' + id);
      return result ? result.data : null;
   }

}

export default LocationService