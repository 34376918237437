import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/scss/_main-dir.scss";
import './assets/less/_main-dir.less';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import GA4React, { useGA4React } from "ga-4-react";
import config from "./config"; 

let ErrorBoundary:any = "";
if(config.BUGSNAG_APIKEY){
    Bugsnag.start({
        apiKey:  config.BUGSNAG_APIKEY,
        plugins: [new BugsnagPluginReact()],
    });
    ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React)
}

const ga4react = new GA4React(config.GOOGLE_ANALYTICS_ID);

(async () => {
    await ga4react.initialize();

    ReactDOM.render(
        config.BUGSNAG_APIKEY? (
            <ErrorBoundary>
                <Router history={createBrowserHistory()}>
                    <Route component={App} />
                </Router>
            </ErrorBoundary>
        ):(
            <Router history={createBrowserHistory()}>
                <Route component={App} />
            </Router>
        ),
        
        document.getElementById("root")
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
