import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { DownOutlined, MenuOutlined, UserOutlined, LogoutOutlined, EnvironmentFilled } from '@ant-design/icons';
import logo from '../../../src/assets/img/logo.png';
import default_image from '../../../src/assets/img/default_image_profile.jpg';
import { message } from 'antd';
//MODAL
import LocationPickerView from './LocationPickerView';
//SERVICE
import LocationService from '../../services/api/LocationService'
import StoreService from '../../services/api/StoreService';
import SearchService from '../../services/api/SearchService';
import { async } from 'q';


const locStyle = { color: '#FDB415', fontSize: 20 };
const HeaderView = (props: any) => {
    let storeService = new StoreService();
    let searchService = new SearchService();
    const [option, setOption] = useState("");
    const [locations, setLocation] = useState<any>();
    const [user, setUser] = useState<any>();
    const [currentActive, setCurrentActive] = useState<any>([]);
    const hideCenter = [
        "/login",
        "/signup",
        "/reset-password",
        "/forgot-password"
    ]
    let locationService = new LocationService();
    const [isLocationModal, setIsLocationModal] = useState(false);
    const isExcludedPath = ["/privacy-notice", "/about", "/contact-us"];

    useEffect(() => {
        let userString: string | null = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
        const userObj = JSON.parse(userString ? userString : '');
        setUser(userObj);

    }, [window.location.href]);

    useEffect(() => {
        initialize()
    }, [] )

    useEffect(() => {
        if(!isExcludedPath.includes(window.location.pathname)){
            setCurrentActive([window.location.pathname.replace("/", "")])
        }
    }, [window.location.pathname] )

    const initialize = async () => {
        let location = localStorage.getItem('location') ? localStorage.getItem('location') : '';
        let path = window.location.pathname;
        let locationResult = await locationService.getAllLocations();
        locationResult = (locationResult || []).sort(function(a:any, b:any){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
        setLocation(locationResult);
        await navigator.geolocation.getCurrentPosition(
            async function(position) {
              let lat = position.coords.latitude;
              let long =  position.coords.longitude
              if(lat.toFixed(6) !== process.env.REACT_APP_DEFAULT_LAT && long.toFixed(6) !== process.env.REACT_APP_DEFAULT_LONG){
                let result = await locationService.getLocation(lat, long)
                if(result){
                    localStorage.setItem('location', result._id);
                    props.onChangeLocation(result._id)
                    if(location !== result._id){
                        if(path.includes("/restaurants/")){
                            checkRestaurant();
                        }else if(path.includes("/order-history")){
                            console.log("history")
                        }else{
                            setOption(result.name);
                            if(localStorage.getItem("access_token") && !isExcludedPath.includes(window.location.pathname)){
                                props.history.push('/')
                            }
                        }
                    } else{
                        if(path.includes("/restaurants/")){
                            checkRestaurant();
                        }else if(path.includes("/order-history")){
                            console.log("history")
                        }else{
                            setOption(result.name);
                            if(localStorage.getItem("access_token") && !isExcludedPath.includes(window.location.pathname)){
                                props.history.push('/')
                            }
                            
                        }
                    }
                    props.onChangeLocation(result._id)
                }else{
                    message.error("Sorry! Your location could not be determined.", 2);
                    let result = await locationService.getLocation(0, 0)
                    if(result){
                        setOption(result.name);
                        localStorage.setItem('location', result._id);
                        if(location !== result._id){
                            if(localStorage.getItem("access_token") && !isExcludedPath.includes(window.location.pathname)){
                                props.history.push('/')
                            }
                        }else{
                            checkRestaurant();
                        }
                        props.onChangeLocation(result._id)
                    }else{
                        setOption(result.name);
                    }
                }
            }else{
                message.error("Sorry! Your location could not be determined.", 2);
                let result = await locationService.getLocation(0, 0)
                if(result){
                    setOption(result.name);
                    localStorage.setItem('location', result._id);
                    if(location !== result._id){
                        if(localStorage.getItem("access_token") && !isExcludedPath.includes(window.location.pathname)){
                            props.history.push('/')
                        }
                    }else{
                        checkRestaurant();
                    }
                    props.onChangeLocation(result._id)
                }else{
                    setOption(result.name);
                }
            }
              
              
        },async function(error){
            message.error("Sorry! Your location could not be determined.", 2);
            let result = await locationService.getLocation(0, 0)
            if(result){
                setOption(result.name);
                localStorage.setItem('location', result._id);
                if(location !== result._id){
                    if(path.includes("/restaurants/")){
                        checkRestaurant();
                    }else if(path.includes("/order-history")){
                        console.log("history")
                    }else{
                        if(localStorage.getItem("access_token") && !isExcludedPath.includes(window.location.pathname)){
                            props.history.push('/')
                        }
                    }
                    
                }else{
                    checkRestaurant();
                }
                props.onChangeLocation(result._id)
            }
        },
        {   enableHighAccuracy: true,
            timeout: 1000,
            maximumAge: 0
        });
    }

    const checkRestaurant = async() =>{
        let path = window.location.pathname;
        if(path.includes("/restaurants/")){
            let slug = path.replace("/restaurants/", "");
            if(slug){
                let storeSlug = await searchService.getStoreId(slug, 1);
                let storeId = storeSlug && storeSlug.storeId ? storeSlug.storeId : "";
                const result = await searchService.getStoreNew(storeId);
                if(result && result.location && result.location._id){
                    setOption(result.location.name);
                    localStorage.setItem('location', result.location._id);
                    props.onChangeLocation(result.location._id)
                }
            }
        }
    }
    const onSelect = (val: any) => {
        let location = localStorage.getItem('location') ? localStorage.getItem('location') : '';
        let result = locations.find((o:any) => o._id === val);
        if(result){
            setOption(result.name);
            localStorage.setItem('location', result._id);
            props.onChangeLocation(result._id)
            if(location !== val && !isExcludedPath.includes(window.location.pathname)){
                props.history.push('/')
            }   
        }
        setIsLocationModal(false)

    }

    const handleClick = (path: any) => {
        props.history.push(path);

    }

    const onClickLocationPicker = (status:any) =>{
        setIsLocationModal(status);
    }
    

    const type = (
        <Menu>
            
            {locations ? locations.map((element:any, i:any) => 
                <Menu.Item key={i} onClick={() => onSelect(element._id)}>
                    <span>{element.name}</span>
                </Menu.Item>
            ) : null}
        </Menu>
    );

    const onLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('auth');
        localStorage.removeItem('resto_id');
        localStorage.removeItem('items');
        localStorage.removeItem('reservation_details');
        localStorage.removeItem('store_name');
        localStorage.removeItem('delivery_address');
        localStorage.removeItem('store_geolocation');
        localStorage.removeItem('reservation_fee');
        localStorage.removeItem('delivery_fee');
        localStorage.removeItem('driver_tip');
        localStorage.removeItem('birth_info');
        localStorage.removeItem('promo_code');
        localStorage.removeItem("availability_id");
        localStorage.removeItem("type");
        localStorage.removeItem("time");
        localStorage.removeItem("date");
        localStorage.removeItem("ebike_delivery");
        localStorage.removeItem('fblst_215076617256957');
        props.history.push('/');
    }

    const menuWeb = (
        <Menu mode="horizontal" selectedKeys={currentActive}>
            <Menu.Item key="profile">
                <a onClick={handleClick.bind(null,'/profile')}><div>Profile</div></a>
            </Menu.Item>
            <Menu.Item key="order-history">
                <a onClick={handleClick.bind(null,'/order-history')} ><div>Order History</div></a>
            </Menu.Item> 
            <Menu.Item key="3">
                <div onClick={onLogout}>
                    <span><LogoutOutlined />&nbsp;Log out</span>
                </div>
            </Menu.Item>
        </Menu>
    );

    const menu = (
        <Menu className="header_menu">
            <Menu.Item key="0" className="mobile-menu-border">
                <a onClick={handleClick.bind(null,'/about')}>ABOUT</a>
            </Menu.Item>
            <Menu.Item key="2" className="mobile-menu-border">
                <a onClick={handleClick.bind(null,'/driver-registration')}>Become a Driver Partner with appétit</a>
            </Menu.Item>
  
            {/* <Menu.Divider /> */}
            {/* <Menu.Item key="1">
            <a href="/faq">FAQ</a>
          </Menu.Item> */}
            {/* <Menu.Divider /> */}

            {localStorage.getItem("access_token") ? 
             (<>  
                <Menu.Item key="2" className="mobile-menu-border">
                    <a onClick={handleClick.bind(null,'/profile')} className="order-history"><div>PROFILE</div></a>
                </Menu.Item>
                 <Menu.Item key="3" className="mobile-menu-border">
                    <a onClick={handleClick.bind(null,'/order-history')} className="order-history">ORDER HISTORY</a>
                </Menu.Item>
                <Menu.Item key="4" className="mobile-menu-border">
                    <a onClick={onLogout}  className="order-history">LOG OUT</a>
                </Menu.Item>
            </>) : (

                    <Menu.Item key="3" className="mobile-menu-border">
                        <a href="/login">LOG IN</a>
                    </Menu.Item>

                )}

            {/* <Menu.Divider /> */}
            {!localStorage.getItem("access_token") ? (
                <Menu.Item key="5" className="mobile-menu-border">
                    <a href="/signup">SIGN UP</a>
                </Menu.Item>
            ) : (
                    null
                )}
        </Menu>

    );


    return (
        <div className="stickyHeader">
            <div className="header_web">
                {window.location.pathname !== '/login' && window.location.pathname !== '/signup' ? (
                    <div className="header">
                        <div className="header__left">
                            <div className="header_tab">
                            </div>
                            <div className="header__logo">
                                <a onClick={handleClick.bind(null,'/')}>
                                    <div className="logo_img">
                                        <img src={logo} alt="appetit logo" />
                                    </div>
                                </a>
                            <div>
                            </div>
                            </div>
                            <div className="header_tab">
                                <a onClick={handleClick.bind(null,'/about')}>About</a>
                            </div>
                            <div className="header_tab">
                                <a  onClick={handleClick.bind(null,'/driver-registration')}>Become a Driver Partner with appétit</a>
                            </div>
                            
                        </div>
                        {!hideCenter.includes(window.location.pathname) ? (
                            <div className="header__center">
                                <div className="header_tab">
                                    {/* <img src={location} alt="location icon" /> */}
                                    <EnvironmentFilled style={locStyle}/>
                                </div>
                                <div className="header_tab">
                                    {/* LOCATION OLD IMPLEMENTATION */}
                                    {/* <Dropdown overlay={type} >
                                        <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                            {option}
                                            <DownOutlined className="header_icon" />
                                        </span>
                                    </Dropdown> */}
                                    <span className="ant-dropdown-link" onClick={()=>{onClickLocationPicker(true)}}>
                                        {option}
                                        <DownOutlined className="header_icon" />
                                    </span>
                                </div>
                            </div>
                        ) : (
                                null
                            )}

                        {localStorage.getItem("access_token") ? (
                            <div className="header__right">
                                <div className="user_tab">
                                     <div className="user_image_frame">
                                        <img src={(user && user.image)? user.image.url: default_image} alt={(user && user.image && user.image.name)? user.image.name.substr(0,10):'default_image'}/>
                                    </div>
                                   
                                    <Dropdown overlay={menuWeb}>
                                        <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                            <MenuOutlined />
                                        </span>
                                    </Dropdown>
                                </div>
                            </div>

                        ) : (
                                <div className="header__right">
                                    <div className={window.location.pathname === '/login' ? "header_tab active" : "header_tab"}>
                                        <a href="/login">Log In</a>
                                    </div>
                                    <div className="header_tab">
                                        <a href="/signup"><div className="header_button">
                                            Sign Up
                                </div> </a>
                                    </div>
                                    <div className="header_tab">
                                    </div>
                                </div>
                            )}
                    </div>
                ) : (
                        <div className="header">
                            <div className="header__left">
                                <div className="header_tab">
                                </div>
                                <div className="header__logo">
                                    <a onClick={handleClick.bind(null,'/')}>
                                        <div className="logo_img">
                                            <img src={logo} alt="appetit logo" />
                                        </div>
                                    </a>
                                <div>
                            </div>
                                </div>
                                <div className="header_tab">
                                    <a onClick={handleClick.bind(null,'/about')}>About</a>
                                </div>
                                <div className="header_tab">
                                    <a onClick={handleClick.bind(null,'/driver-registration')}>Become a Driver Partner with appétit</a>
                                </div>
                               
                                
                                {/* <div className="header_tab">
                            <a href="/faq">FAQ</a>
                        </div>  */}
                            </div>

                            <div className="header__right">
                                <div className={window.location.pathname === '/login' ? "header_tab active" : "header_tab"}>
                                    <a href="/login">Log In</a>
                                </div>
                                <div className={window.location.pathname === '/signup' ? "header_tab active" : "header_tab"}>
                                    <a href="/signup">Sign Up</a>
                                </div>
                                <div className="header_tab">
                                </div>
                            </div>
                        </div>
                    )}
            </div>
            <div className="header_mobile">
                <div className="header">
                    <div className="header__left">
                        <div className="header_tab" style={{zIndex: 99}}>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <span style={{marginTop: "5px"}} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <MenuOutlined style={{fontSize: 20}}/>
                                </span>
                            </Dropdown>
                        </div>
                        {/* TEMPORARY SCALE FOR NOW */}
                        <div className="header__logo" style={{transform: 'scale(1.7)'}}>
                            <a onClick={handleClick.bind(null,'/')}>
                                <div className="logo_img">
                                    <img src={logo} alt="appetit logo" />
                                </div>
                            </a>
                        </div>
                        
                    </div>
                    {!hideCenter.includes(window.location.pathname) ? (
                        <div className="header__center" style={{zIndex: 99}}>
                            <div className="header_tab">
                                {/* <img src={location} alt="location icon" /> */}
                                <EnvironmentFilled style={locStyle}/>
                            </div>
                            <div className="header_tab">
                                <span className="ant-dropdown-link" onClick={()=>{onClickLocationPicker(true)}}>
                                    {option}
                                    <DownOutlined className="header_icon" />
                                </span>
                            </div>
                        </div>
                    ) : (
                            null
                        )}

                </div>
            </div>
            <LocationPickerView visible={isLocationModal} onClickLocation={onSelect} onClose={onClickLocationPicker} activeLocation={option} locations={locations}/>
        </div>
    );
}

export default withRouter(HeaderView)