import React, { useEffect, useState } from 'react';
import { Form, Modal, Input, Button } from 'antd';
import DialogUtils from '../../utils/DialogUtils';
import { EnvironmentOutlined } from '@ant-design/icons';
import { configConsumerProps } from 'antd/lib/config-provider';

interface LocationPickerViewProps {
    visible?:boolean,
    onClickLocation:Function,
    onClose:Function,
    activeLocation: any
    locations?:any | null
}

const LocationPickerView = (props:LocationPickerViewProps) => {
    const [form] = Form.useForm();  
    const iconStyle = { fontSize: 30, borderRadius: 30 };

    const onCancel = () => {
        form.resetFields();
        props.onClose();
    }

    const onClickLocation = async (address : any) =>
    {
        props.onClickLocation(address._id)
    }

    return (
        <div className='location_modal'>
           <Modal
                forceRender
                visible={props.visible}
                centered
                className='address_modal_container success-modal'
                cancelText="Cancel"
                onCancel={onCancel}
                width={250}
                style={{borderRadius: 5}}
                footer={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                closable={false}
                maskClosable={false}>
                <div className='modal_icon_container'>
                    <div className='modal_icon'>
                        <EnvironmentOutlined style={{ ...iconStyle, color: "#FFFFFF" }}/>
                    </div>
                </div>
                <div className='margin-bottom-10px margin-top-10px'>
                    {props.locations && props.locations.map((location:any, i) =>  {
                        return <div className={props.activeLocation && props.activeLocation == location._id.name ? "location_suggestion_active location_suggestion" : "location_suggestion"} onClick={()=> {onClickLocation(location)}} key={i.toString()} >
                            {location.name? location.name : ""}
                        </div>;
                    })}
                </div>
            </Modal>
        </div>
    );
}



export default LocationPickerView