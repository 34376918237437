import React, { Component } from "react";
import "./App.css";
import axios from "axios";
import Loader from "./components/Loader";
import LayoutView from "./modules/layout/LayoutView";
import lazyComponentLoader from "./hoc/LazyLoader";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./hoc/ProtectedRoute";
import { isArray } from "util";
import { message } from "antd";
import DialogUtils from "./utils/DialogUtils";
import { Provider } from "react-redux";
import { createStore } from "redux";
import UnprotectedRoute from "./hoc/UnprotectedRoute";
import TagManager from 'react-gtm-module'
import APPConfig from "./config";
import GA4React from "ga-4-react";
import config from "./config";

interface IAppState {
    isLoading: Boolean;
    errorVisible: Boolean;
    deletePopupCtr: number
}

//include modules

const Login: any = lazyComponentLoader(
    () => import("./modules/auth/login/Login")
);
const Forgot: any = lazyComponentLoader(
    () => import("./modules/auth/forgot-password/ForgotPassword")
);
const Reset: any = lazyComponentLoader(
    () => import("./modules/auth/forgot-password/ResetPassword")
);
const SignupView: any = lazyComponentLoader(
    () => import("./modules/auth/signup/Signup")
);
const DriversRegistrationpView: any = lazyComponentLoader(
    () => import("./modules/auth/signup/DriverSignup")
);
const Home: any = lazyComponentLoader(() => import("./modules/home/HomeView"));
const NewRestaurantDetailView: any = lazyComponentLoader(
    () => import("./modules/restaurant/NewRestaurantDetailView")
);
const RestaurantSearchView: any = lazyComponentLoader(
    () => import("./modules/restaurant/RestaurantSearchView")
);
const OrderPickupView: any = lazyComponentLoader(
    () => import("./modules/order/OrderPickupView")
);
const OrderView: any = lazyComponentLoader(
    () => import("./modules/order/OrderView")
);
const CompleteOrderView: any = lazyComponentLoader(
    () => import("./modules/order/CompleteOrderView")
);
const OrderHistoryView: any = lazyComponentLoader(
    () => import("./modules/customer_profile/OrderHistoryView")
);
const AboutUsView: any = lazyComponentLoader(
    () => import("./modules/about-us/AboutUsView")
);
const PrivacyPolicyView:any = lazyComponentLoader(
    () => import("./modules/privacy-policy/PrivacyPolicyView")
);
const ContactUsView: any = lazyComponentLoader(
    () => import("./modules/contact-us/ContactUsView")
);
const CustomerProfilrView: any = lazyComponentLoader(
    () => import("./modules/customer_profile/CustomerProfileView")
);
const EditCustomerProfileView: any = lazyComponentLoader(
    () => import("./modules/customer_profile/EditCustomerProfileView")
);

const RestaurantSearchResults: any = lazyComponentLoader(
    () => import("./modules/restaurant/RestaurantSearchViewNew")
);

const ChangePasswordView: any = lazyComponentLoader(
    () => import("./modules/customer_profile/ChangePasswordView")
);

const StripeCompleteOrderView: any = lazyComponentLoader(
    () => import("./modules/order/StripeCompleteOrderView")
);

const RestaurantNewSearchViewNew: any = lazyComponentLoader(
    () => import("./modules/restaurant/RestaurantNewSearchViewNew")
);

const initialState = {
    location: "",
};

function reducer(state = initialState, action: any) {
    if (action.location) {
        return { ...state, location: action.location };
    } else {
        return state;
    }
}

const store = createStore(reducer);

class App extends Component<any, IAppState> {
    constructor(props: any) {
        super(props);

        //initialize state here
        this.state = {
            isLoading: false,
            errorVisible: true,
            deletePopupCtr: 0
        };

        const self = this;
        axios.interceptors.request.use(
            function (config) {
                // spinning start to show
                self.setState({ isLoading: true });

                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            function (response) {
                // spinning hide
                self.setState({ isLoading: false });

                return response;
            },
            async (error) => {
                if (error.response) {
                    if (error.response.data.errors) {
                        this.showError(error.response.data.errors);
                    } else {
                        this.showError(error.response.data);
                    }
                } else {
                    this.showError(error);
                }
                self.setState({ isLoading: false });
                return Promise.reject(error);
            }
        );
    }

    componentDidMount() {
        // ReactGA.initialize("G-210L6Y1SHX");
        const ga4react = new GA4React(config.MEASUREMENT_ID);
        if (ga4react.gtag) {
            ga4react.gtag('js', new Date());
            ga4react.gtag('config', config.MEASUREMENT_ID); // Replace with your Measurement ID
        }
    }
    showError(errors: any) {
        let content: any = null;
        if (isArray(errors)) {
            errors.forEach((err: any) => {
                if((err?.context?.key === 'refresh_token') || (err?.context?.key === 'access_token')){
                    DialogUtils.error("You have been logged out. Please login again.", ()=> {
                        localStorage.clear();
                    })
                }else{
                  content = err.message;
                }
            });
        }
        else if ((errors.key === "resource_not_found" || errors.key === "not_found") && errors.resource_type == "voucher") {
            //if voucher not exist
            content = "Promo Code is not available.";
        } else if(errors != 'Unauthorized'){
            content = errors.message.includes("Network Error")
                ? "No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again."
                : errors.message;
        }
        
        if(errors.message === 'deleted account' || content === 'deleted account'){
            this.setState({deletePopupCtr: this.state.deletePopupCtr + 1})
            if(this.state.deletePopupCtr < 2){
                DialogUtils.error("Your account has been deleted. Please register again.", ()=> {
                    localStorage.clear();
                    window.location.href = "/";
                    this.setState({deletePopupCtr: 0})
                })
            }
            
        }else if(content){
            content = (content.includes("timeout") || content.includes("access_token") || content.includes("jwt")) ? "Your session has timed out." : content;
            const err = content ? content.split("Error: ") : "";
            DialogUtils.error(err[1] ? err[1] : content);
        }
    }

    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Provider store={store}>
                            <LayoutView>
                                <Switch>
                                    <Route exact path="/login" component={Login} />
                                    <UnprotectedRoute
                                        exact
                                        path="/forgot-password"
                                        component={Forgot}
                                    />
                                    <UnprotectedRoute
                                        exact
                                        path="/reset-password"
                                        component={Reset}
                                    />
                                    <Route exact path="/" component={Home} />
                                    <Route
                                        exact
                                        path="/signup"
                                        component={SignupView}
                                    />
                                    <Route
                                        exact
                                        path="/driver-registration"
                                        component={DriversRegistrationpView}
                                    />
                                    <Route
                                        exact
                                        path="/restaurants/:id"
                                        component={NewRestaurantDetailView}
                                    />
                                    {/* <Route exact path='/restaurants' component={RestaurantSearchView}/> */}
                                    <Route
                                        exact
                                        path="/restaurants-search"
                                        component={RestaurantNewSearchViewNew}
                                    />
                                    <Route
                                        exact
                                        path="/restaurants"
                                        component={OrderPickupView}
                                    />
                                    <Route
                                        exact
                                        path="/about"
                                        component={AboutUsView}
                                    />

                                    <Route
                                        exact
                                        path="/privacy-notice"
                                        component={PrivacyPolicyView}
                                    />

                                    <Route
                                        exact
                                        path="/contact-us"
                                        component={ContactUsView}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/orders"
                                        component={OrderView}
                                    />
                                    {/* <ProtectedRoute
                                        exact
                                        path="/confirm-purchase"
                                        component={CompleteOrderView}
                                    /> */}
                                    <ProtectedRoute
                                        exact
                                        path="/confirm-purchase"
                                        component={StripeCompleteOrderView}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/order-history"
                                        component={OrderHistoryView}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/profile"
                                        component={CustomerProfilrView}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/edit-profile"
                                        component={EditCustomerProfileView}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/change-password"
                                        component={ChangePasswordView}
                                    />
                                    <Route component={Home} />
                                </Switch>
                            </LayoutView>
                        </Provider>
                    </Switch>
                </Router>

                {this.state.isLoading ? <Loader /> : null}
            </div>
        );
    }
}

export default App;
function initializeGA4(gtag: any, arg1: string) {
    throw new Error("Function not implemented.");
}

