import CustomerAPIService from "./CustomerAPIService";
import LogService from "./LogService";


class SearchService extends CustomerAPIService
{
  

  search = async (search:any = '', page:any = 1, limit:any = 10, date:any, time:any, type:any, guest:any=0, isAsap:any=0) => {
   const result = await this.get('v2/stores/search?generic=' + search + '&page=' + page + '&limit=' + limit + '&date=' + date+ '&time=' + time+ '&type=' + type+ '&guest=' + guest + '&isAsap=' + isAsap);
   return result ? result.data : null;
   }

   homeSearch = async ({search = '', date, time, type, isAsap = 0, page = 1, limit = 10}, location : any = "") => {
      const params = date ? '&date=' + date+ '&time=' + time+ '&isAsap=' + isAsap : '';
      const result = await this.get('v2/stores/search?generic=' + search + '&type=' + type + '&page=' + page + '&limit=' + limit+ '&location=' + location + params);
      return result ? result.data : null;
   }

   isAllowAsap = async (id:any='', type:any='') => {
      const result = await this.get('v2/stores/is_Allow_Asap?store_id=' + id + '&type=' + type);
      return result ? result.data : null;
   }

   searchAvailability = async (storeId:any, time:any, date:any, type:any,timezone:any, guest:any=0, isAsap:any=0) => {
      const result = await this.get('v2/stores/'+ storeId+'/search?date=' + date+ '&time=' + time+ '&type=' + type+ '&timezone=' + timezone+ '&guest=' + guest+ '&isAsap=' + isAsap);
      return result ? result.data : null;
   }

   getResto = async (page:any = 1, limit:any = 10, location:any="", isRandom:any = 0, type="") => {
      const result = await this.get('v2/stores?page=' + page + '&limit=' + limit + '&location=' + location  + '&isRandom=' + isRandom + '&type=' + type);
      return result ? result.data : null;
   }

   nearbyStore = async (search:any = '', page:any = 1, limit:any = 10, location_x:any = 0, location_y:any = 0) => {
      const result = await this.get('v2/stores/nearby?generic=' + search + '&page=' + page + '&limit=' + limit+ '&location_x=' + location_x+ '&location_y=' + location_y);
      
      return result ? result.data : null;
  
   }

   mostPopularStore = async (search:any = '', page:any = 1, limit:any = 10, location:any="") => {
      const result = await this.get('v2/stores/most_popular?generic=' + search + '&page=' + page + '&limit=' + limit + '&location=' + location);
      return result ? result.data : null;
   }

   featuredStore = async (search: any = '', page: any = 1, limit: any = 10, location: any = "") => {
      const result = await this.get('v2/stores_mobile/featured?generic=' + search + '&page=' + page + '&limit=' + limit + '&location=' + location);
      return result ? result.data : null;
   }

   getStore = async (id:any) => {
      const result = await this.get('v2/stores/'+id);
      return result ? result.data : null;
   }

   getStoreNew = async (id:any) => {
      const result = await this.get('v2/stores/resto/'+id);
      return result ? result.data : null;
   }

   getStoreId = async (slug:any, isForLocation = 0) => {
      const result = await this.get('v2/stores/getId/'+slug+'?isForLocation='+isForLocation);
      return result ? result.data : null;
   }

}

export default SearchService