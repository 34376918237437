import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const UnprotectedRoute = ({component:Component, ...rest}:any) => {
    return (
        <Route
            {...rest}
            render={props => 
                {
                    if(localStorage.getItem('access_token')) {
                        let userString: string | null = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
                        const userObj = JSON.parse(userString ? userString : '');
                        if(userObj.onboardingAccounts.length){
                            return <Redirect to={
                                {
                                    pathname:'/restaurant_detail',
                                    state:{},
                                }
                            }/>
                        }else{
                            return <Redirect to={
                                {
                                    pathname:'/onboarding',
                                    state:{},
                                }
                            }/>
                        }
                        
                    }
                    else{
                        return <Component {...props} />
                    }
                }
            }
        />

    );
}

export default UnprotectedRoute;