import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';


const FooterView = (props:any) => {
   
    const onclickContactUs = () =>{
        props.history.push('/contact-us');
    }

    const onclickAboutUs = () =>{
        props.history.push('/about');
    }

    const onclickPrivacy = () =>{
        props.history.push('/privacy-notice');
    }

    return (
        <div>
            <div className="footer_web">
                <div className="footer">
                    <div className="footer__center">
                        <div className="footer_row_new">
                            <div className="footer_tab">
                                <a onClick={onclickAboutUs}>About</a>
                            </div>
                            {/* <div className="footer_tab">
                                <a href="/">FAQ</a>
                            </div> */}
                            <div className="footer_tab">
                                |
                            </div>
                            <div className="footer_tab">
                                <a onClick={onclickPrivacy}>Privacy Policy</a>
                                {/* <a href={process.env.REACT_APP_API_ENDPOINT+ "/pdf/PRIVACY-NOTICE.pdf"} target="_blank" rel="noopener noreferrer">Privacy Policy</a> */}
                            </div>
                            <div className="footer_tab">
                                |
                            </div>
                            <div className="footer_tab">
                                <a onClick={onclickContactUs}>Contact Us</a>
                            </div>
                        </div>
                        <div className="footer_row">
                            <div className="footer_tab">
                                <p>
                                    Copyright © {moment().format("YYYY")} appétit, Buffalo New York - All Rights Reserved
                                </p>
                            </div>
                        </div>
                        <div className="footer_row">
                            <div className="footer_tab">
                                <p>
                                    Powered by - SavyCraft | Ark Digital
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_mobile">
                 <div className="footer">
                    <div className="footer__center">
                        <div className="footer_row_new">
                            <div className="footer_tab">
                                <a onClick={onclickAboutUs}>About</a>
                            </div>
                            {/* <div className="footer_tab">
                                <a href="/">FAQ</a>
                            </div> */}
                            <div className="footer_tab">
                                |
                            </div>
                            <div className="footer_tab">
                                <a onClick={onclickPrivacy}>Privacy Policy</a>
                                {/* <a href={process.env.REACT_APP_API_ENDPOINT+ "/pdf/PRIVACY-NOTICE.pdf"} target="_blank" rel="noopener noreferrer">Privacy Policy</a> */}
                            </div>
                            <div className="footer_tab">
                                |
                            </div>
                            <div className="footer_tab">
                            <a onClick={onclickContactUs}>Contact Us</a>
                            </div>
                        </div>
                        <div className="footer_row">
                            <div className="footer_tab">
                                <p className="hidden-xs">
                                    Copyright © {moment().format("YYYY")}  appétit, Buffalo New York - All Rights Reserved
                                </p>
                                <p className="visible-xs">
                                    Copyright © {moment().format("YYYY")}  appétit, Buffalo New York<br/>All Rights Reserved
                                </p>
                            </div>
                        </div>
                        <div className="footer_row">
                            <div className="footer_tab">
                                <p>
                                    Powered by - SavyCraft | Ark Digital
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(FooterView)