
/**
 * App configuration
 */
class APPConfig
{
    /**
     * api url endpoint
     */
    static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:3000/api';
    static MERCHANT_URL:string = process.env.REACT_APP_MERCHANT_SIGN_UP_URL ? process.env.REACT_APP_MERCHANT_SIGN_UP_URL : '/signup';
    static DELIVERY_SHARE_FEE:number = process.env.REACT_APP_DELIVERY_SHARE_FEE ? parseFloat(process.env.REACT_APP_DELIVERY_SHARE_FEE) : 2;
    static APPETIT_DELIVERY_SHARE_FEE:number = process.env.REACT_APP_APPETIT_DELIVERY_SHARE_FEE ? parseFloat(process.env.REACT_APP_APPETIT_DELIVERY_SHARE_FEE) : 2;
    static APPETIT_PICK_UP_SHARE_FEE:number = process.env.REACT_APP_APPETIT_PICK_UP_SHARE_FEE ? parseFloat(process.env.REACT_APP_APPETIT_PICK_UP_SHARE_FEE) : 1;
    static MIN_AGE_REQUIRED:number = process.env.REACT_APP_MIN_AGE_REQUIRED? parseInt(process.env.REACT_APP_MIN_AGE_REQUIRED) : 21;
    static DEFAULT_TIMEZONE:string = process.env.REACT_APP_DEFAULT_TIMEZONE ? process.env.REACT_APP_DEFAULT_TIMEZONE : 'America/New_York';
    static ALLOW_SERVICE_FEE:number = process.env.REACT_APP_ALLOW_SERVICE_FEE? parseInt(process.env.REACT_APP_ALLOW_SERVICE_FEE) : 1;
    static VOUCHERIFY_ID:string = process.env.REACT_APP_VOUCHERIFY_ID? process.env.REACT_APP_VOUCHERIFY_ID : '';
    static VOUCHERIFY_TOKEN:string = process.env.REACT_APP_VOUCHERIFY_TOKEN? process.env.REACT_APP_VOUCHERIFY_TOKEN : '';
    static VOUCHERIFY_API:string = process.env.REACT_APP_VOUCHERIFY_API? process.env.REACT_APP_VOUCHERIFY_API : '';
    static GOOGLE_ANALYTICS_ID:string = process.env.REACT_APP_GOOGLE_ANALYTICS_ID? process.env.REACT_APP_GOOGLE_ANALYTICS_ID : '';
    static BUGSNAG_APIKEY:string = process.env.REACT_APP_BUGSNAG_APIKEY? process.env.REACT_APP_BUGSNAG_APIKEY : '';
    //reCAPTCHA
    static RECAPTCHA_SITE_KEY:string = process.env.REACT_APP_RECAPTCHA_SITE_KEY ? process.env.REACT_APP_RECAPTCHA_SITE_KEY : '';
    static RECAPTCHA_SECRET_KEY:string = process.env.REACT_APP_RECAPTCHA_SECRET_KEY ? process.env.REACT_APP_RECAPTCHA_SECRET_KEY : '';
    static GOOGLE_PLAY_LINK:string = process.env.REACT_APP_GOOGLE_PLAY_LINK ? process.env.REACT_APP_GOOGLE_PLAY_LINK : '';
    static APPLE_STORE_LINK:string = process.env.REACT_APP_APPLE_STORE_LINK? process.env.REACT_APP_APPLE_STORE_LINK: '';
    static STRIPE_PK: string = process.env.REACT_APP_STRIPE_PK? process.env.REACT_APP_STRIPE_PK: "";
    static MEASUREMENT_ID: string = process.env.REACT_APP_MEASUREMENT_ID? process.env.REACT_APP_MEASUREMENT_ID: "";
    
}

export default APPConfig;