import React from 'react';
import { Modal } from "antd";
import { ExclamationCircleOutlined} from '@ant-design/icons';
import ModalContent from '../components/ModalContent';



export default class DialogUtils {

    /**
     * 
     * @param content 
     * @param onOk 
     * @param onCancel 
     */
    static confirm(content:any, onOk?:Function, onCancel?:Function) {
        // Modal.confirm({
        //     icon: <ExclamationCircleOutlined />,
        //     centered: true,
        //     content: content,
        //     onOk: () => { if(onOk) onOk() },
        //     onCancel: () => { if(onCancel) onCancel() }
        //   });
        Modal.confirm({
            icon: null,
            centered: true,
            className: 'success-modal',
            okButtonProps: {
                shape: 'round',
                id: 'modal-success-btn'
            },
            cancelButtonProps: {
                shape: 'round',
                id: 'modal-cancel-btn'
            },
            content: <ModalContent type={'confirm'} content={content} />,
            onOk: () => { if(onOk) onOk() },
            onCancel: () => { if(onCancel) onCancel() },
            okText: "Yes"
          });
    }

    
    /**
     * 
     * @param content 
     * @param onOk 
     */
    static error(content:any, onOk?:Function) {
        Modal.error({
            icon: null,
            centered: true,
            className: 'error-modal',
            okButtonProps: {
                shape: 'round',
                id: 'modal-error-btn'
            },
            content: <ModalContent type={'error'} content={content} />,
            onOk: () => { if(onOk) onOk() }
          });
    }


    /**
     * 
     * @param content 
     * @param onOk 
     */
    static success(content:any, onOk?:Function) {
        Modal.success({
            icon: null,
            centered: true,
            className: 'success-modal',
            okButtonProps: {
                shape: 'round',
                id: 'modal-success-btn'
            },
            content: <ModalContent type={'success'} content={content} />,
            onOk: () => { if(onOk) onOk() }
          });
    }
}